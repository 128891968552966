* {
  margin: 0%;
  padding: 0%;
  font-family: 'Ubuntu', sans-serif;
}

*::-webkit-scrollbar {
  width: 5px;
  background: #e9e9e9;
}

*::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background: #ff9c04;
}

.body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

/* Header */

.Header {
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff9c04;
}

#contenedorHeader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  background-color: #ff9c04;
}

#mensajeProp {
  display: none;
}

#iconMenuHeader {
  width: 20%;
  height: 100%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

#ContMenuLog {
  width: 8rem;
  height: 80%;
  display: flex;
}

.ContenedorLogo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: 100% 100%;
}

.ContenedorLogo img {
  width: 180%;
  height: 80%;
  margin-left: 2.5rem;
}

.ContenedorBuscador {
  width: 40%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
}

#inputBuscador {
  width: 92%;
  height: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 1rem;
  background: transparent;
}

#contenedorLupa {
  width: 8%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
}

.bntLupa {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#NavHeader {
  width: 12rem;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#containers {
  width: 11.5rem;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: transparent;
}

#logos {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

#TextIcons {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
}

#buttonNav {
  cursor: pointer;
  width: 2.3rem;
  height: 2.3rem;
  border: none;
  background: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#iconHeader {
  width: 100%;
  height: 100%;
  font-size: 35px;
  font-weight: 280;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  cursor: pointer;
}

.addWork {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
}

/* Parte central */

.Principal {
  width: 100vw;
  height: 90vh;
  background: transparent;
  display: flex;
}

/* Menu Lateral */
.containerMenuOculto {
  width: 100vw;
  height: 90vh;
  top: 10vh;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.58);
  position: absolute;
  z-index: 100;
  visibility: hidden;
  transition: all 200ms ease-in-out;
}

.containerMenu {
  width: 100vw;
  height: 90vh;
  top: 10vh;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.58);
  position: absolute;
  z-index: 100;
  transition: all 200ms ease-in-out;
}

/* @media screen and (max-height: 667px) {
  .containerMenuOculto {
    top: 66.5px;
  }

  .containerMenu {
    top: 66.5px;
  }
} */

.MenuLateralEstaticoOculto {
  width: 20rem;
  height: auto;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(222, 222, 222);
  border-bottom-right-radius: 5px;
  z-index: 20;
  top: 3.6rem;
  transition: all 500ms ease;
  transform: translateX(-100%);
}

.MenuLateralEstatico {
  width: 20rem;
  height: auto;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(222, 222, 222);
  border-bottom-right-radius: 5px;
  z-index: 20;
  top: 3.6rem;
  transition: all 500ms ease;
  transform: translateX(0%);
}

#btnCloseMenu {
  width: 100%;
  height: 100%;
  position: absolute;
  background: none;
  border: none;
  cursor: auto;
  content: none;
}

.ContainerIconoBarras {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-left: 4px solid transparent;
}

.ContainerAnclas {
  width: 100%;
  height: auto;
  background: transparent;
}

.MenuAnclas {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerHome {
  width: 99%;
  height: 4rem;
  background: transparent;
  border-right: 4px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerHome:hover {
  border-right: 4px solid #ff9c04;
}

.AnclaMenuHome {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

#btnClose {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-right: 4px solid transparent;
  border: none;
  cursor: pointer;
}

#btnClose:hover {
  border-right: 4px solid #ff9c04;
  background: #8d8d8d7c;
}

#btnClose p {
  font-size: 16px;
}

#NoSesion {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  text-align: center;
}

#textNoSesion {
  padding: 5px;
}

.btnLogout {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: transparent;
  list-style: none;
  text-decoration: none;
  border: none;
  font-size: 1.05vw;
  font-style: oblique;
}

.AnclaMenu {
  width: 99%;
  height: 4rem;
  background: transparent;
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-right: 4px solid transparent;
  cursor: pointer;
  border: none;
}

#iconMenu {
  left: 0;
  position: absolute;
  margin-left: 2rem;
  font-size: 30px;
}

.AnclaMenu:hover {
  border-right: 4px solid #ff9c04;
  background: #8d8d8d7c;
}

.AnclaMenuHome {
  width: 99%;
  height: 4rem;
  background: transparent;
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;
  border: none;
  border-right: 4px solid transparent;
}

.AnclaMenuHome:hover {
  background: #8d8d8d7c;
}

.Ancla {
  width: fit-content;
  height: 100%;
  background: transparent;
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;
  border: none;
}

.AnclaNav {
  flex-direction: column;
}

.AnchorNotification{
  position: relative;
}

.cantNotifications{
  width: 50%;
  height: 50%;
  top: 0;
  right: 0;
  clip-path: circle();
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  font-size: 12px;
}

.ContainerIconoConfiguracion {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-left: 4px solid transparent;
}

/* Centro */

.PContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #e9e9e9;
  overflow-y: auto;
}

.ContenidoCentral {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  transition: 0.5s all ease-in-out;
}

#noResults {
  font-size: 32px;
  font-weight: bolder;
}

.CartaT {
  width: 19rem;
  height: 20rem;
  background: #ffffff;
  display: flex;
  justify-content: space-evenly;
  margin: 1.2rem;
  cursor: pointer;
  object-fit: cover;
  transition: 0.35s;
  border-radius: 6px;
  border: 0.1rem solid #ffb84e;
  border-radius: 30px;
}

.CartaT:hover {
  transform: translate(0, -2px);
  box-shadow: 0 20px 30px -20px rgba(0, 0, 0, 0.623);
  z-index: 1;
}

.ui.pointing.above.prompt.label {
  font-size: 12px;
  color: red;
}

.card {
  position: relative;
  width: 19rem;
  height: 20rem;
  color: #2e2d31;
  background: #131313;
  overflow: hidden;
  border-radius: 20px;
  margin: 1.2rem;
  cursor: pointer;
  object-fit: cover;
  transition: 0.35s;
}

.temporary_text {
  width: 100%;
  height: 75%;
  font-weight: bold;
  font-size: 24px;
  color: #f8f8f8;
}

.card_title {
  font-weight: bold;
  z-index: 4;
}

.card_content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 17rem;
  padding-inline: 1rem;
  padding-bottom: 1rem;
  background: #f2f2f2;
  border-top-left-radius: 20px;
  transform: translateY(120px);
  transition: transform .25s;
  max-height: 260px;
}

.card_content::before {
  content: '';
  position: absolute;
  top: -47px;
  right: -45px;
  width: 100px;
  height: 100px;
  max-height: 260px;
  transform: rotate(-175deg);
  border-radius: 50%;
  box-shadow: inset 48px 48px #f2f2f2;
  z-index: 3;
}

.card_title {
  width: 272px;
  height: 100px;
  max-height: 100px;
  line-height: 30px;
  font-size: 22px;
  font-weight: bold;
  color: #eb9800;
}

.card_subtitle {
  height: 100px;
  display: block;
  font-size: 16px;
  text-overflow: ellipsis;
}

.card_description {
  height: 117px;
  max-height: 177px;
  font-size: 16px;
  opacity: 0;
  transition: opacity .5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-overflow: ellipsis;
}

#cont-card-p {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 7px;
  flex-direction: row;
}

#sub-description {
  font-weight: bold;
}

#sub-description2 {
  height: 22px;
  max-height: 22px;
  width: 198px;
  max-width: 198px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Número de líneas a mostrar */
  -webkit-box-orient: vertical;
  white-space: normal;
}

#cont-card-description {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 7px;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

#work-description {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Número de líneas a mostrar */
  -webkit-box-orient: vertical;
  white-space: normal;
}

.card:hover {
  transform: translate(0, -2px);
  box-shadow: 0 20px 30px -20px rgba(0, 0, 0, 0.623);
  z-index: 1;
}

.card:hover .card_content {
  transform: translateY(0);
}

.card:hover .card_description {
  opacity: 1;
  transition-delay: .25s;
}

.work-item-info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.optionsFavRep {
  background-color: #ffffffb3;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  height: 4vh;
  position: absolute;
  width: 4vw;
  border: 1px solid #f1a101;
}

.optionsFavRep1 {
  display: none;
}

.optionsFavRepWorks {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.optionsFavRepWorks2 {
  width: 100%;
  height: 80%;
}

.btnReportFav {
  width: 4vw;
  height: 4vh;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: hsla(0, 0%, 100%, .7);
  cursor: pointer;
  position: relative;
  border: 1px rgba(255, 140, 0, 0.316) solid;
  z-index: 10;
}

.optionsFavRep:hover,
.btnReportFav:hover {
  background: white;
}

.modalReport {
  display: none;
}

.modalReport2 {
  width: 25vw;
  height: 50vh;
  background-color: orange;
  position: absolute;
  top: 25vh;
  left: 35vw;
  z-index: 2;
  padding: 2vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.btnReportWork {
  width: 90%;
  height: 12%;
  display: flex;
  justify-content: space-evenly;
}

.btnReportWork button {
  width: 40%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
}

.contentReport {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkReport {
  width: 10%;
  height: 80%;
  margin-left: 10px;
}

.contentReport p {
  font-size: 14px;
  margin-left: 12px;
}

.work-image {
  width: 100%;
  height: 100%;
}

.imageWork {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.imageWork2 {
  width: 80%;
  height: 90%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.work-text {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 18px;
}

.work-text-name {
  font-weight: bold;
  font-size: 19px;
}

.work-buttons {
  width: 70%;
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 4;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.work-buttons button {
  width: 90%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  border-style: none;
  border: 1px solid #ffb84e;
  transition: 0.3s;
}

.work-buttons button:hover {
  border: 1px solid #8d8d8d;
  background-color: #ffb84e;
}

.anclaWorks {
  width: 40%;
  height: 100%;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  color: black;
  background-color: #f6f5f5;
  border: 2px double orange;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* INICIO WORK PATH */
.worksPath {
  width: 100%;
  height: fit-content;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.containerWork {
  margin: 1rem;
  width: 52%;
  height: 92%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5%;
}

.containerWork h2 {
  margin-top: 10px;
  font-size: 28px;
}


.PrincipalImage {
  width: 100%;
  height: 60vh;
}

.PrincipalImage img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: opacity 0.3s ease-in-out;
  object-fit: cover;
}

.slider {
  width: fit-content;
  max-width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 3rem;
  border: 1px solid gray;
  border-radius: 10px;
}

.slider:hover {
  transform: translate(0, -5px);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
  transition: 0.35s all ease-in-out;
}

.slide-track {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: 2;
  border-radius: 10px;
}

.slide {
  width: max-content;
  margin: 0 .5rem 0 .5rem;
  height: 70%;
  background-size: 100% 100%;
  object-fit: cover;
  animation: slider 10s linear infinite;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: 100% 100%;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.slider img:hover {
  box-shadow: 0 20px 30px -20px rgba(0, 0, 0, 0.623);
  transform: scale(1.05);
}

.containImgOptions {
  margin-top: .5rem;
  width: 100%;
  height: 30%;
  display: flex;
}

#DateOptionsWorks {
  width: 4vw;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 3.5%;
  right: 18.5%;
  opacity: 1;
  z-index: 4;
  background: white;
  clip-path: circle();

}

.arrowMessagePath {
  background: white;
  border: none;
}

.arrowMessagePath span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-buttons3Path {
  width: 15vw;
  height: 20vh;
  padding: 1.8rem;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  position: absolute;
  transform-origin: right top;
  top: 7%;
  transform: scale(1);
  opacity: 1;
  right: 1.5%;
  border-radius: 5px;
}

.buttonPostulates {
  width: 90%;
  height: 30%;
  font-size: 13px;
  background-color: #ff9c04;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  transition: 0.5s;
  margin-bottom: 2px;
  border: none;
  border-radius: 5px;
}

.work-buttons2 {
  width: 80%;
  height: 10%;
  display: none;
}

.work-buttons3 {
  width: 3vw;
  height: 4vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 8px;
  position: relative;
}

.btnOptionWork {
  width: 90%;
  height: 30%;
  font-size: 13px;
  background-color: #ff9c04;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  transition: 0.5s;
  margin-bottom: 2px;
  border: none;
  border-radius: 5px;
}

.btnOptionWorkPost {
  width: 25%;
  height: 100%;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #d98900;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  transition: 0.5s;
  margin-bottom: 2px;
  border-radius: 4px;
  border-style: none;
}

.btnOptionWorkPost:hover {
  background-color: #f39a00;
}

.btnOptionWork:hover {
  transition: 0.5s;
  background-color: #fba92fde;
}


.imgWork {
  width: 100%;
  height: 100%;
}

.workText {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.textWork1 {
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.priceWork {
  width: 120px;
  height: 50px;
  background-color: #767875;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  margin: 10px;
}

.numberPriceWork {
  color: #0d0f0c;
  font-weight: bold;
  font-size: 24px;
  margin-left: 15px;
}

.imgWork2 {
  width: 150px;
  height: 120px;
  margin-right: 8px;
}

.contDescription {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.containUserCalification {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fcfcfc;
}

.containerCalification {
  width: 35%;
  height: 10vh;
  display: flex;
  align-items: center;
}

.containerCalification p {
  font-size: 50px;
  font-weight: bold;
  margin-left: 8px;
}

.calification {
  margin-left: 8px;
}

.calification p {
  font-size: 22px;
}

.userCalification {
  width: 99%;
  height: 14vh;
  background-color: #e9e9e9;
  margin-top: 20px;
}

.descCalificacion {
  width: 92%;
  height: 35%;
  background-color: #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2% 0 2%;
}

.userCalification p {
  font-size: 18px;
  color: #000000;
  padding: 10px 4% 0 4%;
}

.descCalificacion p {
  font-size: 20px;
  font-weight: bold;
}

/* FIN WORK PATH */

/* INICIO PERFIL */
.Profile {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containPerfil {
  width: 100%;
  height: 100%;
  display: flex;
}

#ProfileMain {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ProfileLeft {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: #e4e4e4;
}

#UserProfileUp {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  font-size: 35px;
}

#modalConfirmDone {
  color: greenyellow;
}

#modalConfirmError {
  color: red;
}

#ModalConfirm {
  width: 15vw;
  height: 3.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  gap: 1rem;
  top: 90%;
  left: 42.5vw;
  right: 42.5vw;
  transform: translate(-50%, -50%);
  transform: translateY(0%);
  transition: all 500ms ease-in-out;
  z-index: 10000;
}

#ModalConfirmHide {
  width: 15vw;
  height: 3.5rem;
  background: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  gap: 1rem;
  top: 90%;
  left: 42.5vw;
  right: 42.5vw;
  transform: translate(-50%, -50%);
  transform: translateY(+1000%);
  transition: all 500ms ease-in-out;
  z-index: 10000;
  visibility: hidden;
}

.modalLogout {
  width: 30vw;
  height: 20vh;
  top: 15vh;
  left: 36vw;
  background-color: #d98900;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10001;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalLogout {
  color: white;
}

.divBtnLogout {
  width: 90%;
  height: 35%;
  display: flex;
  justify-content: space-around;
}

.divBtnLogout button {
  color: black;
  width: 35%;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  background-color: #e6e6e6;
  font-size: 1.3vw;
}

.divBtnLogout button:hover {
  background: #979797;
  transition: 0.5s;
}

.modalLogout1 {
  display: none;
}

#imgUser {
  width: 12rem;
  height: 12rem;
  border: 0.8rem white solid;
  border-radius: 50%;
  background-size: cover;
}

#UserProfileDown {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#anchorsInfo {
  width: 60%;
  height: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.AnInfo {
  width: 10rem;
  height: 3rem;
  background: #f9fafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: black;
  font-size: 18px;
}

#ProfileRight {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  background: #f3f3f3;
}

.BtnProfile {
  width: 70%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: black;
  font-size: 32px;
  background: #e4e3e3;
  cursor: pointer;
  border: none;
  border: double 2.4px white;
  /* border: 1px solid #000; */
  font-weight: bold;
  transition: 0.5s;
}

.BtnProfile:hover{
  font-size: 38px;
  transition: 0.5s;
}

.BtnProfile-logout {
  width: 70%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: rgb(8, 8, 8);
  font-size: 32px;
  background: #5e5e5e;
  cursor: pointer;
  border: double 2.4px white;
  transition: 0.5s;
  font-weight: bold;
}

.BtnProfile-logout:hover {
  color: white;
  transition: 0.5s;
  font-size: 35px;
}

.BtnActive {
  width: 70%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: black;
  font-size: 32px;
  background: #cdcdcd;
  cursor: pointer;
  border: 1px solid #f39a00;
}

/* MODAL */

#confirmImgs {
  width: 18vw;
  height: 3.5rem;
  background: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  gap: 1rem;
  top: 90%;
  left: 42.5vw;
  right: 42.5vw;
  transform: translate(-50%, -50%);
  transform: translateY(0%);
  transition: all 500ms ease-in-out;
  z-index: 10000;
}

#confirmImgsHide {
  width: 18vw;
  height: 3.5rem;
  background: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  gap: 1rem;
  top: 90%;
  left: 42.5vw;
  right: 42.5vw;
  transform: translate(-50%, -50%);
  transform: translateY(+1000%);
  transition: all 500ms ease-in-out;
  z-index: 10000;
  visibility: hidden;
}

.middle {
  width: 2rem;
  height: 2rem;
}

.bar {
  width: 12.5%;
  height: 100%;
  background: #fff;
  display: inline-block;
  transform-origin: bottom center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
  animation: loader 1.2s linear infinite;
}

.bar1 {
  animation-delay: 0.1s;
}

.bar2 {
  animation-delay: 0.2s;
}

.bar3 {
  animation-delay: 0.3s;
}

.bar4 {
  animation-delay: 0.4s;
}

.bar5 {
  animation-delay: 0.5s;
}

.bar6 {
  animation-delay: 0.6s;
}

.bar7 {
  animation-delay: 0.7s;
}

.bar8 {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scaleY(0.1);
    background: transparent;
  }

  50% {
    transform: scaleY(1);
    background: #f39a00;
  }

  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}

.ModalContainer {
  width: 100vw;
  height: 90vh;
  top: 10vh;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.58);
  position: absolute;
  z-index: 10;
  transition: all 200ms ease-in-out;
}

.ModalContainerHide {
  width: 100vw;
  height: 90vh;
  top: 10vh;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.58);
  position: absolute;
  z-index: 10;
  transition: all 200ms ease-in-out;
  z-index: 10;
  visibility: hidden;
  transition: all 200ms ease-in-out;
}

.modalProfile2 {
  width: 32vw;
  height: 68vh;
  background-color: rgb(162, 152, 133);
  position: absolute;
  left: 35vw;
  top: 18vh;
  display: none;
  flex-direction: column;
  font-size: 22px;
  align-items: center;
  padding: 7px;
}

.modalProfile3 {
  width: 32vw;
  height: 80vh;
  /* height: auto; */
  background-color: orange;
  position: absolute;
  top: 4.5vh;
  left: 35vw;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  align-items: center;
  padding: 7px;
  z-index: 2;
  border-radius: 10px;
}

.dropzone {
  width: 30vw;
  height: 4rem;
  text-align: center;
  z-index: 10;
}

#divDropText {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

#textFile2 {
  display: inline-block;
  width: 100%;
  height: 42px;
  line-height: 34px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  font-size: 1.3vw;
}

#textFileEmpty2 {
  display: flex;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.3vw;
}

#imagesWorks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .4rem;
  max-width: 28vw;
  width: auto;
  height: 5rem;
  background: rgba(155, 155, 155, 0.582);
  overflow: hidden;
  border-radius: 10px;
  padding: 0 10px 0 10px;
  position: relative;
}

#imgPrev {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 10vw;
  height: 90%;
  position: relative;
}

#imgPrev img {
  width: 0;
  height: 100%;
  object-fit: cover;
  flex-grow: 1;
  opacity: .8;
  transition: .5s ease;
}

#imgPrev img:hover {
  cursor: crosshair;
  width: 350px;
  opacity: 1;
  flex-grow: 1;
  filter: contrast(120%);
  z-index: 10;
}

#imgPrev:not(:hover) #btnImg {
  z-index: 1;
}

#ContBtnImg {
  width: 100%;
  height: 4.8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  background: none;
}

#btnImg {
  width: 2vw;
  height: 3.6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: rgb(255, 72, 72);
  border: 1px solid rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.322);
  border-radius: 3px;
  cursor: pointer;
  z-index: 11;
}

.dateModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/* FORMULARIO */

.user-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.user-form__avatar {
  width: 80%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.user-form__avatar .image {
  width: 46%;
  height: 95%;
  clip-path: circle();
  border: dashed 3px rgb(9, 8, 5);
}

.user-form input {
  width: 26vw;
  height: 40px;
  font-size: 15px;
}

#inputContainerUser {
  position: relative;
  width: 26vw;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#inputImage {
  width: 100%;
  height: 100%;
  font-size: 15px;
  z-index: 2;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
}

#input-file_btn {
  display: flex;
  width: 100%;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 2.5px solid white;
  color: white;
  line-height: 22px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-size: 16px;
}

#textFileEmpty {
  display: flex;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.3vw;
}

#textFile {
  display: inline-block;
  width: 100%;
  height: 42px;
  line-height: 34px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  font-size: 1.3vw;
}

#ImgUserSelected {
  display: flex;
  width: 9vw;
  height: 18vh;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-size: 100% 100%;
}

.formDivButton {
  width: 95%;
  height: 9.5%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 6px;
}

.formDivButton .button {
  width: 12vw;
  height: 100%;
  background-color: #f4f1ed;
  border: solid 1px white;
  border-radius: 10px;
  font-size: 16px;
  font-style: italic;
  cursor: pointer;
  transition: 0.5s;

}

.formDivButton .button:hover {
  transition: 0.5s;
  font-size: 19px;
}

/* FIN PERFIL */

/* NUEVO LOGIN-REGISTRO */
.titleLR {
  font-weight: 700;
  letter-spacing: -1.5px;
  margin: 0;
}

.titleGhost {
  font-size: 50px;
  line-height: 45px;
  margin: 0;
  text-shadow: 0 0 10px rgba(16, 64, 74, 0.5);
}

.textLR {
  font-size: 20px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
  text-shadow: 0 0 10px rgba(16, 64, 74, 0.5);
}

#linkLR {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
  transition: 0.3s ease-in-out;
}

#linkLR:hover {
  color: #4bb6b7;
}

.content {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.content input {
  accent-color: #333;
  width: 12px;
  height: 12px;
}

.content label {
  font-size: 14px;
  user-select: none;
  padding-left: 5px;
}

.buttonLR {
  position: relative;
  border-radius: 20px;
  border: 1px solid #ffb84e;
  background-color: #ffb84e;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  margin: 10px;
  padding: 12px 80px;
  letter-spacing: 1px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.buttonLR:hover {
  letter-spacing: 3px;
}

.buttonLR:focus:active {
  transform: scale(0.95);
}

.buttonLR:focus {
  outline: none;
}

.ghost {
  position: relative;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 700;
  margin: 10px;
  padding: 12px 80px;
  letter-spacing: 1px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  background-color: rgba(225, 225, 225, 0.2);
  border: 2px solid #fff;
  color: #fff;
  cursor: pointer;
}

.formLR {
  width: 55%;
  height: 70%;
  margin-left: 6.6vw;
  /* margin-right: 4rem; */
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  text-align: center;
  border-radius: 1vw;
  border: solid 2px #ffb84e;
}

#inputContainer {
  width: 100%;
  height: 8%;
  padding: 12px 15px;
  position: relative;
  overflow: hidden;
}

#inputContainer .inputLR {
  width: 100%;
  height: 100%;
  background: none;
  color: black;
  padding-top: 20px;
  border: none;
  outline: 0px;
}

#inputContainer .LabelInput {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid #c7c7c7;
}

#inputContainer .LabelInput:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid #ffb84e;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.SpanInput {
  position: absolute;
  bottom: 5px;
  left: 0;
  transition: all 0.3s ease;
  color: black;
}

#inputContainer .inputLR:focus+.LabelInput .SpanInput,
.inputLR:valid+.LabelInput .SpanInput {
  transform: translateY(-150%);
  font-size: 14px;
  color: #ffb84e;
}

#inputContainer .inputLR:focus+.LabelInput:after,
.inputLR:focus:valid+.LabelInput:after {
  transform: translateX(0%);
}

#inputContainer .inputEmailLR {
  width: 100%;
  height: 100%;
  background: none;
  color: black;
  padding-top: 12.5px;
  border: none;
  outline: 0px;
}

#inputContainer .inputEmailLR:focus+.LabelInput .SpanInput,
.inputEmailLR:valid+.LabelInput .SpanInput {
  transform: translateY(-150%);
  font-size: 14px;
  color: #ffb84e;
}

#inputContainer .inputEmailLR:focus+.LabelInput:after,
.inputEmailLR:focus:valid+.LabelInput:after {
  transform: translateX(0%);
}

.container {
  background-color: #dadada;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.login-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .login-container {
  transform: translateX(100%);
}

.register-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .register-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.AnchorRegister {
  text-decoration: none;
  color: #2968c8;
}

.ModalTerms {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.58);
  transition: all 200ms ease-in-out;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ModalTermsHide {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.58);
  transition: all 200ms ease-in-out;
  z-index: 999;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShowcontainerTerms {
  width: 70%;
  height: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  position: relative;
  overflow-y: auto;
}

.ContainTitleTerms {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ff9c04;
  position: absolute;
  top: 0;
}

.ContainTitleTerms h1 {
  padding: 1rem;
}

.ContainerEachTerms {
  width: 100%;
  height: fit-content;
  padding: 1.5rem;
}

.ContainerEachTerms h2 {
  margin-bottom: .5rem;
}

.ContainerEachTerms p {
  font-size: 16px;
}

.btnCloseTerms {
  width: 4vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translate(-100%);
}

.overlay {
  background-image: url('./img/workServices.jpg');
  /* background-color: #f49301; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.titleGhost,
.textLR {
  text-shadow: 3px 3px 5px #000000;
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

#error {
  color: red;
}

/* INICIO LOGIN */
#Login {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(white, orange);
  display: flex;
  overflow-y: hidden;
}

.containerLogin {
  width: 100%;
  height: 100%;
  display: flex;
}

.LoginCard {
  width: 50%;
  height: 100%;
  background-color: #dadada;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imgLogoRecover {
  width: 28%;
  height: 23%;
}

.formLogin {
  width: 64%;
  height: 80%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 1.5vw;
  border-radius: 1vw;
  border: solid 2px orange;
}

.containLogin {
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.inputLogin {
  width: 78%;
  height: 8.5%;
  border-radius: 10px;
  border-style: none;
  font-weight: bold;
  border: 1px solid #faae5c;
  font-size: 1.2rem;
  padding: 10px;
}

.btnLogin {
  width: 45%;
  height: 12%;
  border-radius: 10px;
  border: solid 1px orange;
  font-size: 1.2vw;
  font-style: oblique;
  background-color: #fb9d10;
  cursor: pointer;
}

#loadLogin {
  width: 80%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.anchorLogin {
  width: fit-content;
  height: 100%;
  background: transparent;
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;
  border: none;
  font-size: 15px;
}

.custom-loader {
  width: 50px;
  height: 50px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #E4E4ED #0000;
  animation: s6 1s infinite linear;
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}

.custom-loader::before {
  border-color: #ff9c04 #0000;
  animation: inherit;
  animation-duration: .5s;
  animation-direction: reverse;
}

.custom-loader::after {
  margin: 8px;
}

@keyframes s6 {
  100% {
    transform: rotate(1turn)
  }
}

.informationLogin {
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.informationLogin p {
  font-size: 16px;
}

.containImgLogin {
  width: 50%;
  height: 100%;
  background-color: #f49301ea;
}

.imgLogin {
  width: 100%;
  height: 100%;
}

/* FIN LOGIN */

/* INICIO REGISTRO */

.Register {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: hidden;
}

.RegisterImage {
  width: 48%;
  height: 100%;
  background-color: #9a9a9a;
  background-size: 100% 100%;
}

.imgRegister {
  width: 100%;
  height: 100%;
  filter: brightness(50%);
}

.contentInfo {
  width: 52%;
  height: 100%;
  background-color: #f6f6f6;
  background: linear-gradient(10deg, #f89e3d, #f9fafa);
}

.imgBottomless {
  width: 100%;
  height: 100%;
  border-radius: 80px;
  border: 1px solid #f89e3d;
  cursor: pointer;
}

.logoCompany {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.imgLogo {
  width: 20%;
  height: 80%;
  border-radius: 80px;
}

.TitlePrincipal {
  font-size: 2rem;
  letter-spacing: 10px;
}

.containerRegister {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerPrincipal {
  width: 52%;
  height: 82%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border: solid 2px #faae5c;
}

#InputsRegister {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2%;
  gap: 1rem;
}

.containerTerms {
  /* margin-top: 1.5rem; */
  width: 55%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
}

.containerTerms input {
  width: 2rem;
  height: 2rem;
  margin: 0 10px 0 10px;
}

.containerTerms p {
  line-height: 1.4;
  text-align: left;
  font-size: 14px;
}

.anclasRegister {
  margin-top: 0.6vw;
}

h1 {
  font-size: 2.7vw;
}

input {
  width: 85%;
  height: 30%;
  border-radius: 10px;
  border-style: none;
  font-weight: bold;
  border: 1px solid #faae5c;
  font-size: 1.2rem;
}

.BtnRegistere {
  width: 80%;
  height: 20vh;
  border-radius: 10px;
  border-style: none;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 5%;
  background-color: #faae5c;
}

.BtnRegistere:hover {
  font-size: 1.8rem;
  transition: 0.5s;
}

@media (max-width:1024px) {
  .containerPrincipal {
    width: 25%;
    height: 55%;
  }
}

@media (max-width:768px) {
  .containerPrincipal {
    width: 35%;
    height: 55%;
  }
}

@media (max-width:425px) {
  .containerPrincipal {
    width: 70%;
    height: 55%;
  }
}

@media (max-width:320px) {
  .containerPrincipal {
    width: 90%;
    height: 55%;
  }
}

/* FIN REGISTRO */

/* POSTULADOS */

.principalPostulados {
  width: 100vw;
  height: 100vh;
}

.contenido {
  width: 100%;
  height: 90vh;
  display: flex;
}

.lateralpostulados {
  width: 8%;
  height: auto;
  background-color: rgb(255, 255, 255);
  transition: 0.5s all ease-in-out;
}

.lateralpostulados:hover {
  width: 11.7%;
  transition: 0.5s all ease-in-out;
}

.centralPostulados {
  width: 100vw;
  height: 90vh;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.peoplePostulation {
  width: 45%;
  height: 20%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2%;
  border-radius: 10px;
}

.peoplePostulation p {
  font-weight: bold;
  font-size: 20px;
}

.peoplePostulation a {
  text-decoration: none;
  color: #3d9afe;
  font-weight: bold;
}

.paragraph {
  font-size: 2.7rem;
}

.imgPerson {
  width: 30%;
  height: 80%;
  border-radius: 10px;
}



.seleccionar {
  width: 15%;
  height: 35%;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  transition: 0.5s;
  background-color: #767875;
  font-weight: bold;
}

.seleccionar:hover {
  transition: 0.5s;
  color: white;
  font-size: 15px;
}




/* FIN POSTULADOS */

/* INICIO PROFILE POSTULATES */
.profilePostulates {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardProfile {
  width: 30%;
  height: 95%;
  background-color: #ff9c04;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  border-radius: 5%;
  border: double 4px #9e9b9b;
  font-weight: bold;
}

.CalificaionPersona {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 7%;
  background-color: #fff;
}


.imgProfilePostulate {
  width: 40%;
  height: 30%;
  border-radius: 100%;
  background-color: #f5f5f5;
}

/* FIN PROFILE POSTULATES */

/* INICO POSTULATION */
.viewPostulation {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containPostulation {
  width: 100%;
  height: 90vh;
  display: flex;
  overflow-y: auto;
}

.peoplePostulated {
  width: 100%;
  height: 100%;
  background: #e9e9e9;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.5s all ease-in-out;
}

.containCardPost {
  width: 95%;
  height: 80%;
  display: flex;
  padding-left: 5%;
  flex-wrap: wrap;
}

.buttonPostulates:hover {
  transition: 0.5s;
  background-color: #fba92fde;
}

/* FIN POSTULATION */

.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

/* INICIO CHAT */
#Chat {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.containerChat {
  width: 100%;
  height: 90vh;
  display: flex;
}

.NoChat {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}



.worksChat {
  width: 30%;
  height: 100%;
  background-color: #ebebe8;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
}

.worksChat button:hover{
  background-color: #acacac;
  transition: 0.2s;
}

.chatWorks {
  width: 98%;
  height: 14%;
  margin-top: 3.5px;
  background-color: #c5c3c3;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-size: 1.5vw;
  cursor: pointer;
}

#PrincipalMessages {
  width: 69.8%;
  height: 100%;
  background: white;
}

#messageChat {
  width: 100%;
  height: 100%;
  background-color: #efefeb;
  display: flex;
  flex-direction: column;
  border-inline: 1px solid #ff9c04;
}

#messageChat1 {
  width: 100%;
  height: 90%;
  background-color: #f5f5f4;
  display: flex;
  flex-direction: column;
  border-inline: 1px solid #ff9c04;
}

.containerTitle {
  width: 100%;
  height: 14%;
}

#containerChatT {
  width: 100%;
  height: 90%;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

#containForm {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff9c04;
}

.titleChat {
  width: 100%;
  height: 70%;
  background-color: #8d8d8d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleMessage {
  font-size: 26px;
  font-weight: bold;
}

.messageDefault {
  width: 65%;
  height: auto;
  border-bottom: none;
  position: relative;
  padding: 1vw;
  word-wrap: break-word;
  z-index: 1;
}

.messageWorks {
  margin-left: 18.2vw;
  text-align: start;
  background-color: #b9b8b8;
  border-radius: 0 12px 12px 12px;
  margin-top: 5px;
  display: flex;
}

.messageWorks2 {
  background-color: #D9D9D9;
  margin-top: 5px;
  margin-left: 2vw;
  border-radius: 0 12px 12px 12px;
  display: flex;
}

#DateOptions {
  width: 10%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.arrowMessage {
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
}

.ModalMessage {
  width: 8rem;
  height: 3rem;
  background: white;
  position: absolute;
  border-radius: 5px;
  transform-origin: right top;
  top: 35px;
  transform: scale(1);
  right: 36px;
  z-index: 10;
  border: 1px solid #ff9c04;
}

.ModalShow {
  display: flex;
}

.ModalHide {
  display: none;
}

.btnDelete {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  font-weight: bold;
}

.btnDelete:hover{
  transition: 0.3s;
  background-color: #cbccca;
}

.sendMessage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.message {
  font-size: 1.2vw;
  word-wrap: break-word;
  width: 90%;
}

.textMessage {
  font-size: 20px;
}

.inputMessage {
  border: none;
  width: 82%;
  height: 50%;
  border-radius: 12px;
  font-size: 1.4vw;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  resize: none;
  padding: 15px 8px 8px 18px;
}

.inputMessage:active {
  border: none;
}

.buttonChat {
  width: 8%;
  height: 80%;
  font-size: 10px;
  cursor: pointer;
  clip-path: circle();
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  transition: 0.3s;
}

.buttonChat:hover {
  background-color: #b0aeae;
  transition: 0.3s;
}

.nameWork{
  font-size: 25px;
  font-weight: bold;
}

/* FIN CHAT */

.ui button {
  border: none;
  font-weight: bolder;
  transition: 0.5s;
}

.ui button:hover {
  transition: 0.5s;
  color: white;
  font-size: 15px;
  background-color: #767875;
}


/* ADMIN */
/* INICIO USERS */
#usersAdmin {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containerUsers {
  width: 100vw;
  height: 90vh;
  display: flex;
}

.users {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dadddd;
}

.stateUsers {
  width: 70%;
  height: 95%;
  background-color: #767875;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px double;
  overflow-y: auto;
}

.tabUsers {
  width: 90%;
  height: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tabUsers .ui.menu {
  width: 100%;
  height: 12%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.tabUsers .ui.menu .item {
  width: 46%;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8dbd7;
  border-radius: 0;
  border-top: 4px solid #ff9c04;
  font-size: 25px;
  font-style: italic;
  cursor: pointer;
  margin: 5px;
}

.user-item {
  width: 52.5vw;
  height: 60px;
  display: flex;
  background-color: #d8dbd7;
  justify-content: space-between;
  border: 1px solid #ff9c04;
  margin-bottom: 1px;
  padding: 10px;
  padding-left: 20px;
}

.btnIcon {
  width: 20%;
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.btnIcon button {
  width: 34%;
  height: 90%;
  cursor: pointer;
}

.btnIcon .button span {
  width: 100%;
  height: 100%;
  font-size: 37px;
}

.titleWork{
  color: #ff9c04;
}


#spanEdit1 {
  background-color: #4bf101;
}

#spanEdit2 {
  background-color: #f1a101;
}

#spanDrop {
  background-color: red;
  color: #ffffff;
}

.confirmModal {
  width: 30%;
  height: 25%;
  background-color: #ff9c04;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  left: 510px;
  top: 200px;
  border-radius: 10px;
}

.confirmModal button {
  width: 80px;
  height: 35px;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  opacity: 10;
  transition: 0.5s;
  cursor: pointer;
  background-color: #fff;
}

.confirmModal button:hover {
  background-color: #767875;
}

.user-item__info {
  display: flex;
  align-items: center;
}

.user-item .ui.image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/* FIN USERS */

/* INICIO WORKS */

#worksAdmin {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containerWorks {
  width: 100%;
  height: 100%;
  display: flex;
}

.btnWorks {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0% 4% 0%;
}

.works {
  width: 100%;
  height: 100%;
  background-color: #cacbc9;
  padding: 1vw;
}

/* FIN WORKS */

/* INICIO REPORTS */

#reportsAdmin {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.containerReports {
  width: 100%;
  height: 91.5%;
  display: flex;
}

.reports {
  width: 100%;
  height: 100%;
  background-color: #cacbc9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reports h2 {
  margin: 15px;
}

.worksReports {
  width: 80%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.reportWork {
  width: 100%;
  height: 7rem;
  background-color: #f7f7f7;
  display: flex;
}

.containReport {
  width: 82%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.reportWork:hover {
  background-color: #f2f0ec;
}

.containReport1 {
  width: 15%;
  height: 100%;
  /* background-color: #3bd1ff; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-right: 5px;
}

.titleReport {
  font-weight: bold;
  font-size: 18px;
}

.anclaReport {
  text-decoration: none;
  color: #1a1919;
  font-weight: bold;
}

/* FIN REPORTS */

/* INICIO HISTORIAL */
.auth__forms {
  width: 100%;
  height: 95%;
}

.auth__forms .ui.menu {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.auth__forms .ui.menu .item {
  width: 42%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8dbd7;
  border: 1px solid #ff9c04;
  border-top: 4px solid #ff9c04;
  font-size: 25px;
  font-style: italic;
  cursor: pointer;
}

.workDone {
  width: 90%;
  height: 100%;
  /* background-color: blue; */
  display: flex;
}

.optionsFavRepHistorical{
  background-color: #ffffffb3;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  height: 5vh;
  position: absolute;
  width: 4vw;
  border: 1px solid #f1a101;
}

.optionsCompletedHistorical{
  width: 100%;
  height: 100%;
}

.optionsCompleted {
  width: 10%;
  height: 18%;
}

.btnDone:hover{
  color: white;
}

.btnCompleted {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.completedForm {
  display: none;
}

.completedForm2 {
  width: 20vw;
  height: 40vh;
  background-color: orange;
  position: absolute;
  top: 25vh;
  left: 38.5vw;
  z-index: 2;
  padding: 2vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.textComment {
  font-size: 18px;
}

.inputCalification {
  width: 90%;
  height: 3rem;
  margin-top: 3%;
}

.divStarts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.star {
  font-size: 35px;
  margin-left: 5px;
  margin-right: 5px;
  color: #5e5e5e;
}

.divBtnCalification {
  width: 90%;
  height: 12%;
  display: flex;
  justify-content: space-evenly;
}

.btnCalification {
  width: 7rem;
  border-radius: 15px;
  cursor: pointer;
}

/* .completedForm2 div {
  cursor: pointer;
} */

.starts[type="radio"] {
  /* display:none; */
  width: 20%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.labelStart {
  color: #000000;
  width: 25%;
  height: 20%;
}

.clasificacion {
  direction: rtl;
  /* right to left */
  unicode-bidi: bidi-override;
  /* bidi de bidireccional */
  text-align: center;
  display: flex;
}

.labelStart:hover {
  color: #fcfcfc;
}

.labelStart:hover~.labelStart {
  color: #fc7e7e;
}

.starts[type="radio"]:checked~.labelStart {
  color: #eb3434;
}

.staractive {
  color: yellow;
  font-size: 35px;
  margin-left: 5px;
  margin-right: 5px;
}

#NoUserPersonalizedHistory {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

/* FIN HISTORIAL */

/* RUTAS PERSONALIZADAS CUANDO NO HAY USUARIO */

.NoUserAnchor {
  width: 99.7vw;
  height: 100vh;
}

.AnchorAccount {
  width: 6%;
  background: transparent;
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 4px solid transparent;
  cursor: pointer;
  border: none;
  color: black;
}

#ContainerIconAnchor {
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#iconAnchor {
  font-size: 250px;
}

#account {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid black;
}

#account:hover {
  background: #8080806f;
}

/* POSTULACIONES */

#NoUserPersonalized {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

/* INICIO NOTIFICACIONES */
.titleNotification {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
}

.modalNotification {
  display: none;
}

.modalNotification1 {
  width: 24%;
  height: 50%;
  background-color: #ebebeb;
  position: absolute;
  top: 9vh;
  right: 8.5vw;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 6px;
  border: solid 1px #d98900;
  z-index: 999;
}

.modalNotificationEmpty{
  border-top: 1px solid #d98900;
  width: 100%;
  height: 85%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapNotification {
  width: 95.5%;
  border-top: 1px solid #d98900;
  background-color: #ffffff82;
  padding: 2px 10px 10px 10px;
  cursor: pointer;
}

.mapNotification:hover{
  background-color: #d6d6d6;
}

.mapNotification h2 {
  font-size: 20px;
}

.ui.mini.modal.transition.visible.active.confirmModal {
  z-index: 4;
  padding-left: 20px;
  padding-right: 20px;
}

/* FIN NOTIFICACIONES */

.textWork2 {
  text-align: justify;
  word-wrap: break-word;
}

.containerDrop {
  border: 2.5px solid white;
  width: 28vw;
  height: 42px;
  background: none;
}

.containerDrop2 {
  width: 28vw;
  height: 42px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* RESPONSIVO CELULARmmit */

@media screen and (max-width: 320px) {

  /* INICIO PRINCIPAL */
  #containers {
    width: 40%;
    gap: 1rem;
  }

  #iconHeader {
    font-size: 30px;
  }

  .ContenedorBuscador {
    width: 50%;
    height: 45%;
  }

  #contenedorLupa {
    width: 20%;
  }

  #iconMenuHeader {
    margin-left: 10px;
    font-size: 25px;
  }

  .ContenedorLogo {
    width: 50%;
    height: 60%;
  }

  /* .imgPerson {
    width: 1rem;
    height: 80%;
    margin-right: 1rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  } */

  .MenuLateralEstatico {
    width: 65%;
    font-size: 15px;
    top: -3px;
  }

  /* #btnCloseMenu{
    top: 1px;
  } */

  #iconMenu {
    font-size: 25px;
  }

  .AnclaMenuHome {
    display: flex;
  }

  .MenuLateralEstatico p {
    margin-left: 2rem;
  }


  .CartaT {
    width: 90%;
    height: 25vh;
  }

  .modalProfile3 {
    width: 80%;
    left: 8%;
  }

  .modalProfile3 input {
    width: 100%;
    height: 6vh;
    border-radius: 5px;
  }

  .formDivButton .button {
    width: 100%;
    font-size: 14px;
    background-color: #fff;
  }

  .formDivButton .button:hover {
    background-color: #767875;
    color: #fff;
  }

  .descriptionWork {
    background-color: #000000;
    width: 20rem;
    resize: none;
  }

  /* FIN PRINCIPAL */

  /* INICIO USUARIO NO LOGUEADO */
  .Ancla {
    font-size: 10px;
  }


  /* FIN USUARIO NO LOGUEADO */

  /* INICIO LOGIN Y REGISTRO */

  .overlay {
    display: none;
  }

  .login-container {
    width: 100%;
  }

  .imgBottomless {
    height: 70%;
  }

  .titleLR {
    font-weight: 700;
    font-size: 20px;
  }

  .buttonLR {
    width: 60%;
    display: flex;
    justify-content: center;
  }

  .register-container {
    width: 100%;
    left: -320px;
  }

  .inputEmailLR {
    font-size: 13px;
    margin-top: -5px;
  }

  /* FIN LOGIN Y REGISTRO */

  /* INICIO PERFIL */

  #UserProfileUp p {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .AnInfo {
    height: 30%;
  }

  .AnOptions {
    width: 80%;
    height: 10%;
    font-size: 15px;
  }

  /* FIN PERIL */

  /* INICIO USUARIO NO LOGUEADO */
  #iconAnchor {
    font-size: 110px;
  }

  #NoUserPersonalized h2 {
    font-size: 20px;
  }

  #NoUserPersonalized p {
    font-size: 11px;
  }

  #account {
    width: 100vw;
  }

  /* FIN USUARIO NO LOGUEADO */

  /* INICIO HISTORIAL DE TRABAJOS */
  .auth__forms .ui.menu .item {
    font-size: 13px;
  }

  /* FIN HISTORIAL DE TRABAJOS */

  /* INICIO APARTADO DE TRABAJO */

  .containerWork {
    width: 100%;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .imgWork {
    width: 100%;
  }

  .imgWork2 {
    width: 40px;
    height: 30px;
  }

  .priceWork {
    width: 60px;
    height: 20px;
  }

  .contDescription {
    font-size: 12px;
  }

  .textWork1 {
    font-size: 10px;
  }

  .textWork2 {
    text-align: justify;
  }

  .containerWork h2 {
    font-size: 15px;
    color: #ff9c04;
  }

 
  .btnOptionWorkPost {
    width: 50%;
    height: 50%;
  }

  .containUserCalification p {
    font-size: 15px;
  }

  #DateOptionsWorks {
    width: 23%;
    height: 50%;
  }

  /* FIN APARTADO DE TRABAJO */

  /* INICIO CHAT| */
  .titleMessage {
    font-size: 15PX;
  }

  #messageChat {
    height: 90%;
  }

  .inputMessage {
    font-size: 15px;
  }

  /* FIN CHAT */

  /* INICIO USUARIOS ADMIN */
  .tabUsers .ui.menu .item {
    font-size: 12px;
  }

  .stateUsers {
    width: 90%;
  }

  .user-item {
    width: 70vw;
    font-size: 10px;
  }

  .btnIcon button {
    width: 70%;
    height: 50%;
  }

  #spanEdit1 {
    width: 100%;
    font-size: 20px;
  }

  #spanDrop {
    font-size: 20px;
  }

  #spanEdit2 {
    font-size: 20px;
  }

  /* FIN USUARIOS ADMIN */

  /* INICIO REPORTES */

  .worksReports {
    width: 98%;
    font-size: 10PX;
  }

  .titleReport {
    font-size: 10px;
  }

  /* FIN REPORTES */

  /* INICIO APARTADO FAVORITOS */

  .containCardPost {
    width: 100%;
    flex-wrap: wrap;
  }

  .containCardPost .CartaT {
    margin-left: 1%;
  }

  .favoritesWork {
    width: 50vw;
    height: 20vh;
    background-color: #3d9afe;
    font-size: 30px;
  }

  /* FIN APARTADO FAVORITOS */

  /* INICIO NOTIFICACIONES */

  .modalNotification1 {
    width: 15rem;
    height: 80%;
    top: 45px;
    margin-right: 2%;
  }

  .modalNotification1 h2 {
    font-size: 18px;
  }

  /* FIN NOTIFICACIONES */

  /* INICIO POSTULACIONES */
  .containPostulation {
    height: 100vh;
    background-color: #e9e9e9;
  }

  .peoplePostulated {
    width: 100%;
    height: 100%;
    /* background-color: #f80808; */
  }

  .containCardPost {
    width: 100%;
  }

  .containCardPost .CartaT {
    height: 50%;
  }

  .confirmModal {
    width: 80%;
    left: 35px;
  }

  /* INICIO POSTULACIONES */

  .imgBottomless {
    width: 100%;
    height: 60%;
    margin-top: 20px;
    margin-left: -8px;
  }

  .BtnProfile {
    height: 10%;
    font-size: 15px;
  }

  #imgUser {
    width: 70%;
    height: 35%;
    border: 2px solid white;
  }

  .containerTerms {
    width: 85%;
    margin-top: -1%;
    gap: 5px;
  }

  .containerTerms p {
    font-size: 10px;
    text-align: justify;
  }

  .peoplePostulation {
    width: 95%;
    font-size: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }

  .centralPostulados {
    width: 100%;
  }

  .centralPostulados h1 {
    font-size: 20px;
    margin-top: 10px;
  }

  .seleccionar {
    width: 20%;
    font-size: 10px;
  }

  /* .imgPerson {
    width: 50px;
    background-color: #4bb6b7;
  } */
}

/* FIN RESPONSIVO CELULAR */